import {Props as SeoProps} from "../components/Seo";

export enum Platform {
  FACEBOOK = 'facebook',
  IMDB = 'imdb',
  INSTAGRAM = 'instagram',
  SPOTIFY = 'spotify',
  YOUTUBE = 'youtube',
}

// Values must match the folder name under src/content
export enum WorkCategory {
  FILMS = 'films',
  SERIES = 'series',
  VIDEO_GAMES = 'video-games',
}

export type Collection = WorkCategory | 'press';

export type WorkPreview = {
  id: string;
  title: string;
  years: number[];
  months: number[];
  slug: string;
  thumbnail: {
    data: {
      childImageSharp: {
        gatsbyImageData: any;
      }
    };
    alt: string;
  };
}

export type WorkFrontmatter = {
  slug: string;
  title: string;
  subcategory: string;
  years: number[];
  videoURL?: string;
  thumbnail: {
    data: any;
    alt: string
  };
  credits: {
    what: string;
    who: string;
  }[];
  seo: Pick<SeoProps, 'title' | 'description' | 'keywords'>
}

export type PressItem = {
  id: string;
  title: string;
  year: number;
  month: number;
  link: string;
  thumbnail: {
    data: any;
    alt: string;
  };
  html: string;
}

export type ContactPerson = {
  address: string;
  role: string;
}

export type SocialMedia = {
  platform: Platform;
  url: string;
}