import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import {Platform} from "../domain";

export const renderSocialIcon = (platform: Platform): JSX.Element => {
  switch (platform) {
    case 'facebook': return <StaticImage placeholder="none" src='../images/social/facebook_white.png' alt="Icon linking to the facebook page of Stavros Markonis" />
    case 'imdb': return <StaticImage placeholder="none" src='../images/social/imdb_white.png' alt="Icon linking to the imdb page of Stavros Markonis" />
    case 'instagram': return <StaticImage placeholder="none" src='../images/social/insta_white.png' alt="Icon linking to the instagram page of Stavros Markonis" />
    case 'spotify': return <StaticImage placeholder="none" src='../images/social/spotify_white.png' alt="Icon linking to the spotify page of Stavros Markonis" />
    case 'youtube': return <StaticImage placeholder="none" src='../images/social/youtube_white.png' alt="Icon linking to the youtube page of Stavros Markonis" />
    default: return <></>
  }
};