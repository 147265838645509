type Theme = {
  palette: {
    primary: string;
    secondary: string;
  }
}

const theme: Theme = {
  palette: {
    primary: '#4C9F96',
    secondary: '#7759a6'
  }
}

export default theme;

export const transitionDuration = 500;