import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import React from 'react';
import styled, {css} from 'styled-components';

const BackgroundsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const cssBackground = css`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 30ms;
`

const BackgroundImage = styled(GatsbyImage)`
  ${cssBackground}
`;

const BlackBackground = styled.div`
  background: black;
  ${cssBackground} 
`

const Backgrounds: React.FC = () => {
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "cover.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1000,
            quality: 100,
            blurredOptions: {width: 20}
          )
        }
      }
    }
  `)
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const image = getImage(bisbikis);
  return (
    <BackgroundsContainer>
      {imageLoaded ? null : <BlackBackground />}
      <BackgroundImage
        onLoad={() => setImageLoaded(true)}
        image={image}
        alt="Portrait image of Stavros Markonis"
      />
    </BackgroundsContainer>
  )
}

export default Backgrounds;
