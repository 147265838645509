import React from 'react';

interface NavigationContextValue {
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  mobileMenuExpanded: boolean;
  setMobileMenuExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultContextValue: NavigationContextValue = {
  location: '/',
  setLocation: () => '',
  mobileMenuExpanded: false,
  setMobileMenuExpanded: () => { },
};

export const NavigationContext = React.createContext(defaultContextValue);

const NavigationProvider = ({children}: {children: React.ReactNode}) => {
  const [location, setLocation] = React.useState<string>('');
  const [mobileMenuExpanded, setMobileMenuExpanded] = React.useState(false)
  return (
    <NavigationContext.Provider value={{
      location,
      setLocation,
      mobileMenuExpanded,
      setMobileMenuExpanded,
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
