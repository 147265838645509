import {ContactPerson, Platform, SocialMedia} from "../domain"

export const contactPersons: ContactPerson[] = [
  {address: 'contact@stavrosmarkonis.com', role: 'Direct Contact'},
  {address: 'feni.benou@gmail.com', role: 'Concept | Content | PR & Communications'},
  {address: 'markonis@cinemble.com', role: 'Cinemble'}
]

export const socialMedia: SocialMedia[] = [
  {
    platform: Platform.IMDB,
    url: 'https://www.imdb.com/name/nm5241312/',
  },
  {
    platform: Platform.SPOTIFY,
    url: 'https://open.spotify.com/playlist/3I92Rq9SJc0MP7Wnl3Vl6Q?si=v8opUxXRS6CRy9KY7sTE5g&nd=1&dlsi=1e8afb3935334165',
  },
  {
    platform: Platform.YOUTUBE,
    url: 'https://www.youtube.com/channel/UCKqBXHYLGRkYaHanW8ECvgQ',
  },
  {
    platform: Platform.FACEBOOK,
    url: 'https://www.facebook.com/markonis.stavros',
  },
  {
    platform: Platform.INSTAGRAM,
    url: 'https://www.instagram.com/stavrosmarkonis',
  }
]

export const contactDescription = 'If you would like to contact Stavros, don\'t hesitate to reach out:'