import {Link} from 'gatsby';
import React, {useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {WorkCategory} from '../domain';
import {NavigationContext} from '../services/navigation';
import {devices} from '../styles/breakpoints';
import theme, {transitionDuration} from '../styles/theme';
import Footer from './Footer';

const mainRoutes = Object.values(WorkCategory);
const mainRouteName: Record<WorkCategory, string> = {
  [WorkCategory.FILMS]: 'films',
  [WorkCategory.SERIES]: 'series',
  [WorkCategory.VIDEO_GAMES]: 'video games',
}
const secondaryRoutes = ['about', 'press', 'contact', 'cinemble'];

const MenuContainer = styled.div`
  font-family: 'Quattrocento sans', sans-serif;
  color: white;
  text-transform: uppercase;
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  height: 60px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.primary}30;
  top: 0;
  width: 100%;
  padding: 16px 24px;
`;

const Markonis = styled(Link)`
  flex: 1;
  display: flex;
  font-size: 22px;
  text-transform: uppercase;
  color: ${theme.palette.primary};
  text-decoration: none;
`;

const MainRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 48px;
  & > a {
    transition: all ${transitionDuration}ms ease-out;
    font-size: 20px;
    text-decoration: none;
    color: white;
    position: relative;
    @media ${devices.tabletPortrait} {
      letter-spacing: 2px;
      font-size: 18px;
      margin: 0;
    }
    @media ${devices.desktop} {
      font-size: 20px;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 20%;
      transform: scaleX(0.7);
      transform-origin: left;
      height: 1px;
      bottom: 3px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all ${transitionDuration}ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary};
      opacity: 0.8;
      &::after {
        opacity: 0.8;
        bottom: 0px;
        left: 0;
        transform: scaleX(1);
      }
    }
    &:focus {
      outline: none;
    }
  }
`;

const SecondaryRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  & > a {
    transition: all ${transitionDuration}ms ease-out;
    font-size: 15px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0px;
    text-decoration: none;
    @media ${devices.tabletPortrait} {
      letter-spacing: 1px;
      font-size: 14px;
    }
    @media ${devices.desktop} {
      letter-spacing: 1px;
      font-size: 15px;
    }
    position: relative;
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      transform: scaleX(0.7);
      transform-origin: left;
      width: 20%;
      height: 1px;
      bottom: 3px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all ${transitionDuration}ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary};
      opacity: 0.8;
      &::after {
        bottom: 1px;
        opacity: 0.8;
        left: 0;
        transform: scaleX(1);
      }

    }
    &:focus {
      outline: none;
    }
  }
`;

export const MobileMenu = ({isHome, show}: {isHome: boolean, show: boolean}) => {
  const ref = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const {mobileMenuExpanded, setMobileMenuExpanded} = useContext(NavigationContext);
  const nameWidth = nameRef.current?.getBoundingClientRect().width ?? 0;
  const menuHeight = ref.current?.getBoundingClientRect().height ?? 0;
  const menuWidth = ref.current?.getBoundingClientRect().width ?? 0;
  useEffect(() => {
    document.documentElement.style.overflowY = mobileMenuExpanded ? 'hidden' : 'auto'
  }, [mobileMenuExpanded])
  useEffect(() => {
    if (!ref.current) return
    setMobileMenuExpanded(isHome)
  }, [isHome])
  return (
    <MenuContainer ref={ref} style={{
      color: 'white', position: 'fixed', top: 0, zIndex: 4,
      backgroundColor: 'black',
      transform: `translateY(${show ? '0' : '-100'}%)`,
      opacity: show ? 1 : 0,
      transition: `all ${transitionDuration}ms ease-out`,
    }}>
      <div ref={nameRef}>
        <Markonis to='/' style={{
          transform: `translateX(${!isHome ? 0 : `${menuWidth / 2 - nameWidth / 2 - 24}px`})`,
          transition: `all ${transitionDuration}ms ease-out`,
        }}>
          Stavros Markonis
        </Markonis>
      </div>
      <div onClick={() => setMobileMenuExpanded(e => !e || isHome)} style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: 16, width: 24, cursor: 'pointer'}}>
        {isHome ? null : (
          <>
            <div style={{height: 1, width: mobileMenuExpanded ? 22 : 24, transformOrigin: 'top right', background: theme.palette.primary, transform: `rotate(${mobileMenuExpanded ? '-45deg' : '0deg'})`, transition: `all ${transitionDuration}ms ease`}} />
            <div style={{height: 1, width: '100%', background: theme.palette.primary, opacity: mobileMenuExpanded ? 0 : 1, transition: `all ${transitionDuration}ms ease`}} />
            <div style={{height: 1, width: '100%', background: theme.palette.primary, opacity: mobileMenuExpanded ? 0 : 1, transition: `all ${transitionDuration}ms ease`}} />
            <div style={{height: 1, width: '100%', background: theme.palette.primary, opacity: mobileMenuExpanded ? 0 : 1, transition: `all ${transitionDuration}ms ease`}} />
            <div style={{height: 1, width: mobileMenuExpanded ? 22 : 24, transformOrigin: 'bottom right', background: theme.palette.primary, transform: `rotate(${mobileMenuExpanded ? '45deg' : '0deg'})`, transition: `all ${transitionDuration}ms ease`}} />
          </>
        )}
      </div>
      <div style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100dvh',
        backgroundColor: isHome
          ? 'rgba(0, 0, 0, 0.6)'
          : mobileMenuExpanded
            ? 'rgba(0, 0, 0, 1)'
            : 'rgba(0, 0, 0, 0)',
        transform: `
          translateX(${mobileMenuExpanded ? 0 : `100%`})
        `,
        transition: `all ${transitionDuration}ms ease-out`,
        pointerEvents: mobileMenuExpanded ? 'auto' : 'none',
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          flexDirection: 'column', gap: 16, padding: 24, paddingTop: 48, opacity: mobileMenuExpanded ? 1 : 0, transition: `all ${transitionDuration}ms ease-in`,
        }}>
          <MainRoutesContainer>
            {mainRoutes.map(route => (
              <Link
                key={route}
                to={`/${route}/`}
                partiallyActive={true}
                activeClassName="active-main-link"
                state={{enableTranslationTransition: isHome}}
              >
                {mainRouteName[route]}
              </Link>
            ))}
          </MainRoutesContainer>
          <SecondaryRoutesContainer>
            {secondaryRoutes.map(sr => (
              <Link
                key={sr}
                to={`/${sr}/`}
                activeClassName="active-secondary-link"
                state={{enableTranslationTransition: isHome}}
              >
                {sr}
              </Link>
            ))}
          </SecondaryRoutesContainer>
        </div>
        <Footer
          style={{
            marginTop: 'auto',
            opacity: mobileMenuExpanded ? 1 : 0,
            // transform: `translateY(${mobileMenuExpanded ? 0 : -20}px)`,
            transition: `all 300ms ${transitionDuration}ms ease-in-out`
          }}
        />
      </div>
    </MenuContainer>
  )
}