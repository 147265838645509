import {Link} from 'gatsby';
import React from 'react';
import {useScrollDirection} from 'react-use-scroll-direction';
import styled from 'styled-components';
import {useMediaQuery} from 'usehooks-ts';
import {WorkCategory} from '../domain';
import {NavigationContext} from '../services/navigation';
import {breakpoints, devices} from '../styles/breakpoints';
import theme, {transitionDuration} from '../styles/theme';
import {MobileMenu} from './MobileMenu';

const mainRoutes = Object.values(WorkCategory);
const mainRouteName: Record<WorkCategory, string> = {
  [WorkCategory.FILMS]: 'films',
  [WorkCategory.SERIES]: 'series',
  [WorkCategory.VIDEO_GAMES]: 'video games',
}
const secondaryRoutes = ['about', 'press', 'contact', 'cinemble'];

const Markonis = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  font-size: 28px;
  text-transform: uppercase;
  color: ${theme.palette.primary};
  text-decoration: none;
`;

const MainRoutesContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  & > a {
    transition: all ${transitionDuration}ms ease-out;
    font-size: 17px;
    text-decoration: none;
    color: white;
    position: relative;
    @media ${devices.tabletPortrait} {
      letter-spacing: 2px;
      font-size: 18px;
      margin: 0;
    }
    @media ${devices.desktop} {
      font-size: 20px;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 20%;
      transform: scaleX(0.7);
      transform-origin: left;
      height: 1px;
      bottom: 3px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all ${transitionDuration}ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary};
      opacity: 0.8;
      &::after {
        opacity: 0.8;
        bottom: 0px;
        left: 0;
        transform: scaleX(1);
      }
    }
    &:focus {
      outline: none;
    }
  }
  & > a:last-child {
    margin-right: -4px;
  }
  @media ${devices.desktop} {
    & > a:last-child {
      margin-right: -5px;
    }
  }
`;

const SecondaryRoutesContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 16px;
  & > a {
    transition: all ${transitionDuration}ms ease-out;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0px;
    text-decoration: none;
    margin-right: 16px;
    @media ${devices.tabletPortrait} {
      letter-spacing: 1px;
      font-size: 14px;
    }
    @media ${devices.desktop} {
      letter-spacing: 1px;
      font-size: 15px;
    }
    position: relative;
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      transform: scaleX(0.7);
      transform-origin: left;
      width: 20%;
      height: 1px;
      bottom: 3px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all ${transitionDuration}ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary};
      opacity: 0.8;
      &::after {
        bottom: 1px;
        opacity: 0.8;
        left: 0;
        transform: scaleX(1);
      }

    }
    &:focus {
      outline: none;
    }
  }
  & > a:last-child {
    margin-right: 0px;
  }
  @media ${devices.tabletPortrait} {
    & > a:last-child {
      margin-right: -1px;
    }
  }
`;

const MenuContainer = styled.div`
  font-family: 'Quattrocento sans', sans-serif;
  color: white;
  text-transform: uppercase;
  z-index: 3;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  border-bottom: 1px solid ${theme.palette.primary}30;
  top: 0;
  width: 100%;
  padding: 16px 24px;
`;

const DesktopMenu = ({isHome, show}: {isHome: boolean, show: boolean}) => (
  <MenuContainer style={{
    backgroundColor: isHome ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 1)',
    transform: `translateY(${show ? '0' : '-100'}%)`,
    opacity: show ? 1 : 0,
    height: 60,
    transition: `all ${transitionDuration}ms ease-out`,
  }}>
    <Markonis to='/'>
      Stavros Markonis
    </Markonis>
    <MainRoutesContainer>
      {mainRoutes.map(mr => (
        <Link
          key={mr}
          to={`/${mr}/`}
          partiallyActive={true}
          activeClassName="active-main-link"
          state={{enableTranslationTransition: isHome}}
        >
          {mainRouteName[mr]}
        </Link>
      ))}
    </MainRoutesContainer>
    <SecondaryRoutesContainer>
      {secondaryRoutes.map(sr => (
        <Link
          key={sr}
          to={`/${sr}/`}
          activeClassName="active-secondary-link"
          state={{enableTranslationTransition: isHome}}
        >
          {sr}
        </Link>
      ))}
    </SecondaryRoutesContainer>
  </MenuContainer>
)

const Menu: React.FC = () => {
  const {location} = React.useContext(NavigationContext);
  const isHome = location === '/';
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`);
  const {scrollDirection} = useScrollDirection();
  const [show, shitshow] = React.useState(true);
  React.useEffect(() => {
    if (!scrollDirection) return;
    shitshow(scrollDirection === 'UP');
  }, [scrollDirection])
  return isDesktop
    ? <DesktopMenu isHome={isHome} show={show} />
    : <MobileMenu isHome={isHome} show={show} />
}

export default Menu;
