import React from 'react';
import styled from 'styled-components';
import {socialMedia} from '../content/contact';
import {NavigationContext} from '../services/navigation';
import {devices} from '../styles/breakpoints';
import theme from '../styles/theme';
import {renderSocialIcon} from '../utils/ui';

const LinksContainer = styled.div`
  /* width: 80%; */
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  margin-top: 32px;
  @media ${devices.tabletPortrait} {
    margin-top: 40px;
    gap: 32px;
    /* a32ign-items: center; */
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 80vw;
      height: 2px;
      top: -32px;
      left: calc(50% - 200px);
      background-color: ${theme.palette.primary};
      opacity: 0.5;
    }
  }
  @media ${devices.desktop} {
    margin-top: 48px;
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 80vw;
    width: 300px;
    height: 2px;
    top: -32px;
    left: calc(50% - 150px);
    background-color: ${theme.palette.primary};
    opacity: 0.5;
  }
`;

const IconLink = styled.a`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline-block;
  & > div {
    transition: transform 200ms ease-out;
    transform: scale(1);
    opacity: 0.8;
  }
  &:hover {
    & > div {
      transform: scale(1.1);
      opacity: 1;
    }
  }
`;

const Footer = ({style = {}}: {style?: any}) => {
  const {location} = React.useContext(NavigationContext);

  return (
    <LinksContainer style={{
      marginTop: location === '/' ? 'auto' : '64px',
      display: 'flex',
      zIndex: 3,
      ...style
    }}
    >
      {socialMedia.map(s => (
        <IconLink
          key={s.platform}
          href={s.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderSocialIcon(s.platform)}
        </IconLink>
      ))}
    </LinksContainer>
  )
}

export default Footer;
