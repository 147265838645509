import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import NavigationProvider, {NavigationContext} from '../services/navigation';
import {devices} from '../styles/breakpoints';
import '../styles/custom.css';
import '../styles/layout.css';
import theme, {transitionDuration} from '../styles/theme';
import Backgrounds from './Backgrounds';
import Footer from './Footer';
import Menu from './Menu';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100dvh;
  position: relative;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 60px;
  flex: 1;
  max-width: 1440px;
  z-index: 2;
  @media ${devices.tabletPortrait} {
    padding: 32px 64px;
  }
  @media ${devices.desktop} {
    padding: 48px 96px;
  }
`;

export const PageContent = React.forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <ContentContainer className={props.className} ref={ref}>
      {props.children}
    </ContentContainer>
  );
});

const PageContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  transition: all ${transitionDuration}ms;
  position: relative;
`;

const Page = ({children}: {children: React.ReactNode}) => {
  return (
    <PageContentContainer>
      {children}
    </PageContentContainer>
  );
};

const Overlay = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
  transition: all 1000ms;
  transition-timing-function: ease-out;
`;

const OverlayC: React.FC = () => {
  const {location} = React.useContext(NavigationContext);
  return (
    <Overlay style={{
      background: 'black',
      opacity: location !== '/' ? 1 : 0
    }} />
  )
}

const Layout = ({children}: {children: React.ReactNode}) => {
  return (
    <ThemeProvider theme={theme}>
      <NavigationProvider>
        <LayoutContainer>
          <Page>
            <Backgrounds />
            <Menu />
            <OverlayC />
            {children}
            <Footer />
          </Page>
        </LayoutContainer>
      </NavigationProvider>
    </ThemeProvider>
  );
};

export default Layout;

